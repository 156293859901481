.debug-button-container {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 10;

  .debug-button {
    pointer-events: auto;
    cursor: pointer;
    padding: 0.8em;
    border: 4px solid #3F86B2;
    border-radius: 8px;
    background-color: #87CEFA;

    .debug-button-icon {
      font-size: 3em;
      color: #3F86B2;
    }

    &:hover {
      background-color: #67AEDA;
    }
  }
}

.debug-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1em;
  background-color: #87CEFACC;
  z-index: 20;
  overflow: scroll;

  .debug-content-button {
    cursor: pointer;
  }

  .debug-close-button {
    cursor: pointer;
    position: absolute;
    right: 0;
    margin-right: 1em;
    width: 3em;
    height: 3em;
    border: 0;
    background-color: transparent;

    .debug-close-button-icon {
      font-size: 3em;
      color: #67AEDA;

      &:hover {
        color: #3F86B2;
      }
    }
  }

  .debug-section {
    margin: 4vh 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .debug-header {
    font-size: 1.5em;
  }

  .debug-state {
    font-size: 12px;
  }
}